import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvService } from '@beathletics/auth';
import { EventTypeWithCategories, IClub, IVenue } from '@beathletics/api-interfaces';
import type { NewCategory, NewCountry, NewFederation } from '@prisma/client';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService {
  #http = inject(HttpClient);
  #env = inject(EnvService);
  apiUrl = this.#env.getEnv('API_URL','/api');
  // *- CATEGORIES -*

  getAllCategories = () => this.#http.get<NewCategory[]>(`${this.apiUrl}/shared/categories`);

  // *- CLUBS -*

  getAllClubs = () => this.#http.get<IClub[]>(`${this.apiUrl}/shared/clubs`);

  processClubProvinceFile = (file: File) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post<IClub[]>(`${this.apiUrl}/shared/clubs/province/file`, formData);
  };

  // *- COUNTRIES -*

  getAllCountries = () => this.#http.get<NewCountry[]>(`${this.apiUrl}/shared/countries`);

  // *- EVENT TYPES -*

  getAllEventTypes = () => this.#http.get<EventTypeWithCategories[]>(`${this.apiUrl}/shared/event-types`);

  editEventTypeProperty = (body: { eventTypeId: string; property: string; value: string | number }) =>
    this.#http.put<EventTypeWithCategories>(`${this.apiUrl}/shared/edit/event-type`, body);

  // *- FEDERATIONS -*

  getAllFederations = () => this.#http.get<NewFederation[]>(`${this.apiUrl}/shared/federations`);

  // *- VENUES -*

  getAllVenues = () => this.#http.get<IVenue[]>(`${this.apiUrl}/shared/venues`);

  editVenueProperty = (body: { venueId: string; property: string; value: string }) =>
    this.#http.put<IVenue>(`${this.apiUrl}/shared/edit/venue`, body);

  processVenueFile = (file: File) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post<IVenue[]>(`${this.apiUrl}/shared/venues/file`, formData);
  };

  createNewVenue = (data: Partial<IVenue>) => this.#http.post<IVenue>(`${this.apiUrl}/shared/venue`, data);

  deleteVenues = (venues: IVenue[]) => this.#http.delete<string[]>(`${this.apiUrl}/shared/venues/delete`, { body: venues });

  switchCompetitionsVenues = (data: { venueIdOne: string; venueIdTwo: string }) =>
    this.#http.put<{ venueOne: IVenue; venueTwo: IVenue }>(`${this.apiUrl}/shared/venues/switch`, data);
}
